import React, { FC, Fragment } from "react"
import classnames from "classnames"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import GatsbyImage from "gatsby-theme-gaviscon/src/components/common/GatsbyImage"
import DangerouslySetInnerHtml from "gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml"

import useScreenRecognition from "gatsby-theme-gaviscon/src/hooks/useScreenRecognition"
import { TopBannerProps } from "gatsby-theme-gaviscon/src/components/TopBanner/model"
import "gatsby-theme-gaviscon/src/components/TopBanner/TopBanner.scss"

const TopBanner: FC<TopBannerProps> = ({
  title,
  description,
  image,
  imageAlt,
  mobileImage,
  mobileImageAlt,
  classes,
  imageStrategy,
  imageStyles,
  bannerBackground,
  isContentWrapped,
}) => {
  const { isMobile, isNotMobile } = useScreenRecognition()
  const cx = classnames("gs-top-banner", {
    [`${classes}`]: classes,
    [`gs-top-banner--background-${bannerBackground?.label}`]: bannerBackground?.label,
  })

  const ContentWrapper = isContentWrapped === "1" ? Container : Fragment

  return (
    <div className={cx}>
      <ContentWrapper fluid className="gs-top-banner__wrapper">
        <div className="gs-top-banner__text">
          <h1 className="gs-top-banner__text-title gs-hero-title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="gs-top-banner__text-line"
                html={value}
              />
            ))}
          </h1>
          {description ? (
            <DangerouslySetInnerHtml
              className="gs-top-banner__text-description"
              html={description}
            />
          ) : null}
        </div>
        {isMobile ? (
          <div className="gs-top-banner__image-mobile">
            {mobileImage?.fallbackUrl ? (
              <GatsbyImage
                className="gs-top-banner__image-img"
                fluid={mobileImage}
                useFocalPoint
                alt={mobileImageAlt}
                isLazyLoading={false}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}
        {isNotMobile ? (
          <div className="gs-top-banner__image-desktop">
            {image?.fallbackUrl ? (
              <GatsbyImage
                className="gs-top-banner__image-img"
                isLazyLoading={false}
                useFocalPoint
                fluid={image}
                imgStyle={imageStyles}
                objectFit={imageStrategy}
                alt={imageAlt}
              />
            ) : null}
          </div>
        ) : null}
      </ContentWrapper>
    </div>
  )
}

export const query = graphql`
  fragment FragmentTopBannerAU on TTopBannerStructure {
    properties {
      image {
        ...FragmentGatsbyImage
      }
      mobileImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          ...FragmentSmallImage
        }
        mobileImage {
          childImageSharp {
            fluid(maxWidth: 320, maxHeight: 220, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      description
      mobileImageAlt
      title {
        value
      }
      imageAlt
      bannerBackground {
        label
      }
    }
  }
`

export default TopBanner
