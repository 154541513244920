import React, { FC } from 'react';

import DropdownCategories from 'components/DropdownCategories';
import CategoriesNavItem from './CategoriesNavItem';
import { ICategoriesNav } from './models';
import './CategoriesNav.scss';

const CategoriesNav: FC<ICategoriesNav> = ({
  selectedTag,
  categories,
  filterTitle,
  filterSubtitle,
}) => {
  return categories.length ? (
    <div className="gs-categories-nav container-fluid">
      {filterTitle ? <div className="gs-categories-nav__title">{filterTitle}</div> : null}
      {filterSubtitle ? <div className="gs-categories-nav__subtitle">{filterSubtitle}</div> : null}
      <div className="mobile-hide">
        <nav className="gs-categories-nav__list">
          <ul>
            {categories?.map(
              (tag) =>
                tag &&
                selectedTag && (
                  <CategoriesNavItem
                    key={tag.id}
                    tag={tag}
                    selected={tag.name === selectedTag[0].name}
                  />
                )
            )}
          </ul>
        </nav>
      </div>

      <div className="desktop-hide">
        <DropdownCategories tags={categories} />
      </div>
    </div>
  ) : null;
};

export default CategoriesNav;
